import { AuthRequest } from "../pages/authRequests/RequestAuth.types";
import { ClientReferral } from "../pages/clientReferrals/ClientReferrals.types";
import { RbtCandidate } from "../pages/rbtCandidates/RbtCandidate.types";
import { RbtRequest, RbtRequestStatus } from "../pages/rbtRequests/RequestRbt.types";
import apiClient from "./axios";

export const api = {
  login: (data: any) => {
    return apiClient.post("/auth/login", data);
  },
  socialLogin: (data: any) => {
    return apiClient.post("/auth/login/social", data);
  },
  markUserAsActive: (id: string) => {
    return apiClient.post(`/users/${id}/active`);
  },
  fetchUserStatus: (id: string) => {
    return apiClient.get(`/users/${id}/active-status`);
  },
  getPatientsCount: (id: string) => {
    return apiClient.get(`/users/${id}/patients-count`);
  },
  getUnreadMessagesCount: (id: string) => {
    return apiClient.get(`/users/${id}/unread-messages-count`);
  },
  getUnreadConversationsCount: (id: string) => {
    return apiClient.get(`/users/${id}/admin-unread-conversation-counts`);
  },
  updateUserStatus: (id: string, data: any) => {
    return apiClient.patch(`/users/${id}/status`, data);
  },
  getDashboardCharts: (data: any) => {
    let url = `/users/${data.id}/reports?type=${data.type}`;
    if (data.duration) {
      url += `&duration=${data.duration}`;
    }
    if (data.startDate) {
      url += `&startDate=${data.startDate}`;
    }
    if (data.endDate) {
      url += `&endDate=${data.endDate}`;
    }
    return apiClient.get(url).then((r) => r.data);
  },
  getBulletins: () => {
    let url = `/bulletin`;
    return apiClient.get(url).then((res) => res.data);
  },
  getPartialFamilySignUps: () => {
    return apiClient.get("/users/admin/initial-family-signups").then((res) => res.data);
  },
  getInactivePartialFamilySignUps: () => {
    return apiClient.get("/users/admin/inactive-initial-family-signups").then((res) => res.data);
  },
  deactivatePartialFamilySignUp: (id: string) => {
    return apiClient.post(`/users/admin/partial-family-signup/deactivate/${id}`)
  },
  reactivatePartialFamilySignUp: (id: string) => {
    return apiClient.post(`/users/admin/partial-family-signup/reactivate/${id}`)
  },
  resendPromptEmailPartialFamilySignUp: (id: string) => {
    return apiClient.post(`/users/admin/partial-family-signup/resend-prompt-email/${id}`)
  },
  getUsers: (data: any, signal?: any) => {
    let url = `/users?page=${data.page}&limit=${data.limit}&showHidden=true`;
    if (data.keyword) {
      url += `&searchKeyword=${data.keyword}`;
    }
    if (data.type) {
      url += `&type=${data.type}`;
    }
    if (data.showAvailable !== undefined) {
      url += `&showAvailable=${data.showAvailable}`;
    }
    if (data.providerTypes && data.providerTypes.length > 0) {
      url += `&providerTypes=${data.providerTypes.join(",")}`;
    }
    if (data.focusAreas && data.focusAreas.length > 0) {
      url += `&focusAreas=${data.focusAreas.join(",")}`;
    }
    if (data.addressState) {
      url += `&addressState=${data.addressState}`;
    }
    if (data.showPending !== undefined) {
      url += `&showPending=${data.showPending}`;
    }
    if (data.showActive !== undefined) {
      url += `&showActive=${data.showActive}`;
    }
    return apiClient.get(url, { signal }).then((res) => res.data);
  },
  getClientProviders: (id: string) => {
    return apiClient.get(`/users/${id}/client-providers`).then((res: { data: { providers: [] } }) => res.data);
  },
  getProviders: (data: any) => {
    let url = `/users?type=${data.type}&page=${data.page}&limit=${data.limit}&showHidden=true`;
    if (data.searchKeyword) {
      url += `&searchKeyword=${data.searchKeyword}`;
    }
    if (data.showAvailable) {
      url += `&showAvailable=${data.showAvailable}`;
    }
    if (data.providerTypes && data.providerTypes.length > 0) {
      url += `&providerTypes=${data.providerTypes.join(",")}`;
    }
    if (data.focusAreas && data.focusAreas.length > 0) {
      url += `&focusAreas=${data.focusAreas.join(",")}`;
    }
    if (data.addressState) {
      url += `&addressState=${data.addressState}`;
    }
    return apiClient.get(url).then((res) => res.data);
  },
  getFamilyProviders: (id: string) => {
    return apiClient.get(`/users/${id}/providers`);
  },
  getProviderClients: (id: string, params: any) => {
    let url = `/users/${id}/patients?page=${params.page}&type=${params.type}`;
    if (params.limit) {
      url += `&limit=${params.limit}`;
    }
    if (params.keyword) {
      url += `&searchKeyword=${params.keyword}`;
    }
    return apiClient.get(url);
  },
  updateScheduleStatus: (id: string, data: any) => {
    return apiClient.patch(`/schedules/${id}/status`, data);
  },
  getMessages: (patientId: string, providerId: string) => {
    return apiClient.get(`/messages?patientId=${patientId}&providerId=${providerId}`);
  },
  removeSchedules: (id: string) => {
    return apiClient.delete(`/schedules/${id}`);
  },
  blockUser: (id: string, data: any) => {
    return apiClient.patch(`/users/${id}/status`, data);
  },
  unBlockUser: (id: string, data: any) => {
    return apiClient.patch(`/users/${id}/status`, data);
  },
  confirmUser: (id: string) => {
    return apiClient.patch(`/users/${id}/confirm`);
  },
  removeUser: (id: string) => {
    return apiClient.delete(`/users/${id}`);
  },
  markAsRead: (data: any) => {
    return apiClient.patch("/messages", data);
  },
  markConversationAsUnread: (contactId: string) => {
    return apiClient.patch(`/messages/mark-conv-unread/${contactId}`);
  },
  sendMessage: (data: any) => {
    return apiClient.post("/messages", data);
  },
  deleteAccount: (id: string) => {
    return apiClient.delete(`/users/${id}`);
  },
  getSchedules: (id: string, field: string) => {
    return apiClient.get(`/schedules?${field}=${id}`);
  },
  scheduleAppointment: (data: any) => {
    return apiClient.post("/schedules", data);
  },
  getScheduleByPatientId: (id: string) => {
    const url = `/schedules?patientId=${id}`;
    return apiClient.get(url).then((res) => res.data);
  },
  getScheduleByProviderId: ({ provider_id, page, limit }: { provider_id: string; page: number; limit: number }) => {
    const url = `/schedules?providerId=${provider_id}&page=${page}&limit=${limit}`;
    return apiClient.get(url).then((res) => res.data);
  },
  getDocuments: (userId: string) => {
    let url = `/documents?userId=${userId}`;
    return apiClient.get(url);
  },
  verifyInsurance: (userId: string, insuranceId: string, data: any) => {
    return apiClient.post(`/users/${userId}/insurances/${insuranceId}/verify`, data);
  },
  moveDocument: (documentId: string, data: any) => {
    return apiClient.patch(`/documents/${documentId}`, data);
  },
  renameDocument: (documentId: string, data: any) => {
    return apiClient.patch(`/documents/${documentId}`, data);
  },
  deleteDocument: (documentId: string) => {
    return apiClient.delete(`/documents/${documentId}`);
  },
  createDocument: (data: any) => {
    return apiClient.post("/documents", data);
  },
  connectStripe: (userId: any, tier: string, planType: string) => {
    return apiClient.get(
      `/users/${userId}/stripe-connect-url?tier=${tier}&planType=${planType}&redirectUrl=${window.location.origin}/stripe?code1=success`
    );
  },
  getSubscription: (userId: any) => {
    return apiClient.get(`/users/${userId}/subscriptions`);
  },
  contentFeedback: (userId: any, data: any) => {
    return apiClient.post(`/users/${userId}/content-feedback`, data);
  },
  cancelSubscription: (userId: string, subscriptionId: string) => {
    return apiClient.delete(`/users/${userId}/subscriptions/${subscriptionId}`);
  },
  getInstagramProfile: (userId: any) => {
    return apiClient.get(`/users/${userId}/social-profile`);
  },
  getInstagramPosts: (userId: any) => {
    return apiClient.get(`/users/${userId}/social-posts`);
  },
  initEmailVerification: (id: any) => {
    return apiClient.post(`/users/${id}/email/verify/initiate`);
  },
  verifyEmail: (id: any, data: any) => {
    return apiClient.post(`/users/${id}/email/verify`, data);
  },
  signup: (data: any) => {
    return apiClient.post("/auth/register", data).then((res) => res.data);
  },
  signupFamily: (data: any) => {
    return apiClient.post("/auth/patient/register", data).then((res) => res.data);
  },
  saveInsurance: (userId: string, data: any) => {
    return apiClient.post(`/users/${userId}/insurances`, data);
  },
  validateEmail: (value: string) => {
    return apiClient.post(`/auth/validate-field`, {
      value,
      fieldType: "email",
    });
  },
  getInsurance: (userId: string) => {
    return apiClient.get(`/users/${userId}/insurances`);
  },
  signupProvider: (data: any) => {
    return apiClient.post("/auth/provider/register", data);
  },
  resendInviteProvider: (userId: string) => {
    return apiClient.post(`/auth/resend-invite/`, { userId });
  },
  createBulletin: (data: any) => {
    return apiClient.post("/bulletin/", data);
  },
  updateBulletin: (data: any) => {
    return apiClient.patch(`/bulletin/${data.id}`, data);
  },
  deleteBulletin: (id: string) => {
    return apiClient.delete(`/bulletin/${id}`);
  },
  getConfig: () => {
    return apiClient.get("/configs").then((res) => res.data);
  },
  saveConfig: (data: any) => {
    return apiClient.post("/configs", data);
  },
  logout: () => {
    return apiClient.post("/auth/logout").then((res) => res.data);
  },
  forgotPassword: (data: any) => {
    return apiClient.post("/auth/forgot-password", data);
  },
  resetPassword: (data: any) => {
    return apiClient.post("/auth/reset-password", data);
  },
  changePassword: (userId: string, data: any) => {
    return apiClient.patch(`/users/${userId}/password`, data);
  },
  getOnboardingSections: () => {
    return apiClient.get("/onboarding/sections").then((res) => res.data);
  },
  getOnboardingSection: (code: string) => {
    return apiClient.get(`/onboarding/sections/${code}`).then((res) => res.data);
  },
  saveOnboardingSection: (code: string, data: any) => {
    return apiClient.put(`/onboarding/sections/${code}`, data);
  },
  getUserProfile: (id: string) => {
    return apiClient.get(`/users/${id}`).then((res) => res.data);
  },
  saveLastActive: (id: string) => {
    return apiClient.post(`/users/${id}/active`);
  },
  saveUserProfile: (id: string, data: any) => {
    return apiClient.put(`/users/${id}`, data);
  },
  generatePrompt: (id: string, data: any, signal?: any) => {
    return apiClient.post(`/users/${id}/prompts`, data, { signal });
  },
  getProgressDashboard: (userId: string, month: string, year: string) => {
    return apiClient.get(`/users/${userId}/progress-dashboard?month=${month}&year=${year}`).then((r) => r.data);
  },
  getDashboard: (userId: string) => {
    return apiClient.get(`/users/${userId}/dashboard`).then((r) => r.data);
  },
  getUserReports: (userId: string) => {
    return apiClient.get(`/users/${userId}/reports`).then((r) => r.data);
  },
  getPredesignedUrl: ({
    id,
    mimeType,
    uploadType,
    fileName,
  }: {
    id: string;
    mimeType: string;
    uploadType: string;
    fileName?: string;
  }) => {
    let url = `/users/${id}/images/signed-url?mimeType=${mimeType}&uploadType=${uploadType}`;
    if (fileName) {
      url += `&fileName=${fileName}`;
    }
    return apiClient.get(url).then((r) => r.data);
  },
  getClientReferrals: async () => {
    return (await apiClient.get("/users/client-referrals/all-client-referrals")).data;
  },
  updateClientReferral: async (clientReferral: ClientReferral) => {
    return await apiClient.patch(`/users/client-referrals/${clientReferral.id}/update-referral`, clientReferral);
  },
  getAuthRequests: async () => {
    return (await apiClient.get("/authrequest")).data;
  },
  getAuthRequestsAsAdmin: async () => {
    return (await apiClient.get("/authrequest/admin/all-requests")).data;
  },
  getAllPatients: async () => {
    return (await apiClient.get("/authrequest/all-patients")).data;
  },
  createAuthRequest: async (createRequest: AuthRequest) => {
    return await apiClient.post("/authrequest", createRequest);
  },
  updateAuthRequest: async (updateRequest: AuthRequest) => {
    return await apiClient.patch(`/authrequest/${updateRequest.id}`, updateRequest);
  },
  deleteAuthRequest: async (id: string) => {
    return await apiClient.delete(`/authrequest/${id}`);
  },
  adminGetAllRbtRequests: async () => {
    let requests = (await apiClient.get("/rbtrequest/admin/all-requests")).data;
    requests = requests?.map((r) => ({
      ...r,
      shiftsNeeded: r.shiftsNeeded.map((shift) => {
        if (!isNaN(Date.parse(shift.startTime))) return shift;

        const newStart = new Date();
        newStart.setHours(shift.startTime.split(":")[0]);
        newStart.setMinutes(shift.startTime.split(":")[1]);
        const newEnd = new Date();
        newEnd.setHours(shift.endTime.split(":")[0]);
        newEnd.setMinutes(shift.endTime.split(":")[1]);
        return {
          ...shift,
          startTime: newStart,
          endTime: newEnd,
        };
      }),
    }));
    return requests ?? [];
  },
  createRbtRequest: async (createRequest: RbtRequest) => {
    let data = createRequest as any;
    data.shiftsNeeded = convertShiftsTimeToCustomFormat(createRequest.shiftsNeeded);
    return await apiClient.post("/rbtrequest", data);
  },
  updateRbtRequest: async (id, updateRequest: RbtRequest) => {
    let data = updateRequest as any;
    if (updateRequest.status === RbtRequestStatus.IN_PROCESS) {
      data.status = "In Progress";
    }
    data.shiftsNeeded = convertShiftsTimeToCustomFormat(updateRequest.shiftsNeeded);
    return await apiClient.patch(`/rbtrequest/${id}`, data);
  },
  deleteRbtRequest: async (id: string) => {
    return await apiClient.delete(`/rbtrequest/${id}`);
  },
  getRbtCandidates: async () => {
    let candidates = (await apiClient.get("/rbtcandidate")).data;
    candidates = candidates?.map((c) => ({
      ...c,
      scheduleAvailability: c.scheduleAvailability.map((shift) => {
        if (!isNaN(Date.parse(shift.startTime))) return shift;

        const newStart = new Date();
        newStart.setHours(shift.startTime.split(":")[0]);
        newStart.setMinutes(shift.startTime.split(":")[1]);
        const newEnd = new Date();
        newEnd.setHours(shift.endTime.split(":")[0]);
        newEnd.setMinutes(shift.endTime.split(":")[1]);
        return {
          ...shift,
          startTime: newStart,
          endTime: newEnd,
        };
      }),
    }));
    return candidates ?? [];
  },
  createRbtCandidate: async (createRequest: RbtCandidate) => {
    let data = createRequest as any;
    data.scheduleAvailability = convertShiftsTimeToCustomFormat(createRequest.scheduleAvailability);
    return await apiClient.post("/rbtcandidate", data);
  },
  updateRbtCandidate: async (id, updateRequest: RbtCandidate) => {
    let data = updateRequest as any;
    data.scheduleAvailability = convertShiftsTimeToCustomFormat(updateRequest.scheduleAvailability);
    return await apiClient.patch(`/rbtcandidate/${id}`, data);
  },
  deleteRbtCandidate: async (id: string) => {
    return await apiClient.delete(`/rbtcandidate/${id}`);
  },
};

const convertShiftsTimeToCustomFormat = (shifts) =>
  shifts.map((shift) => {
    const start = new Date(shift.startTime);
    const startMins = start.getMinutes() < 10 ? `${start.getMinutes()}0` : start.getMinutes();
    const end = new Date(shift.endTime);
    const endMins = end.getMinutes() < 10 ? `${end.getMinutes()}0` : end.getMinutes();
    return {
      ...shift,
      startTime: `${start.getHours()}:${startMins}`,
      endTime: `${end.getHours()}:${endMins}`,
    };
  });
