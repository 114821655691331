import { LoadAnimatedContainer } from "../../../components/LoadAnimatedContainer";
import { ReactComponent as SearchIcon } from "../../../assets/icons/search.svg";
import { ReactComponent as ChevronRight } from "../../../assets/icons/right-chevron.svg";
import { ReactComponent as More } from "../../../assets/icons/more.svg";
import { ReactComponent as DownloadIcon } from "../../../assets/icons/download.svg";
import { ReactComponent as ArrowDown } from "../../../assets/icons/arrow-down-black.svg";
import { ReactComponent as ArrowUp } from "../../../assets/icons/arrow-up-black.svg";
import useFamilies from "../hooks/useFamilies";
import UIPopover from "../../../components/Popover";
import { useEffect, useState } from "react";
import moment from "moment";
import { EmptyStates } from "../../../components/EmptyStates";
import { EditFamilyProfileForm } from "./components/EditFamilyProfileForm";
import { BlockedFamilyList } from "./BlockedFamilyList";
import { DeleteConfirm } from "./components/DeleteConfirm";
import { BlockFamilyConfirm } from "./components/BlockFamilyConfirm";
import { PendingVerificationTooltip } from "./components/PendingVerificationTooltip";
import { ImageView } from "../../../components/Image";
import { api } from "../../../services/api";
import { buildURI } from "../../../services/csv-generator";
import { Button } from "../../../components/Button";
import { Loading } from "../../../components/Loading/Loading";
import { ShowToast } from "../../../services/toast";
import { zipCodes } from "../../rbtRequests/modals/zip_codes";
import { SelectInput } from "../../../components/SelectInput";
import { ClientStatus, clientStatusColor } from "../hooks/patient.types";
import { PartialSignUpFamilyList } from "./PartialFamilyList";

function FamilyListLoadingSkeleton() {
  return (
    <>
      {Array.from({ length: 7 }).map((_, i) => (
        <tr className="hover:bg-gray-100 [&_td]:py-3 border-b" key={i}>
          <td className="name pl-5">
            <div className="flex items-center gap-3">
              <div className="flex flex-col">
                <div className="flex items-center gap-2.5">
                  <div className="w-10 h-10 rounded-full bg-gray-300 animate-pulse transition duration-50"></div>
                  <div className="w-28 h-4 bg-gray-300 animate-pulse transition duration-50"></div>
                </div>
              </div>
            </div>
          </td>
          <td className="account-manager">
            <div className="w-20 h-4 bg-gray-300 animate-pulse transition duration-50"></div>
          </td>
          <td className="account-executive">
            <div className="w-12 h-4 bg-gray-300 animate-pulse transition duration-50"></div>
          </td>
          <td className="subscription">
            <div className="w-40 h-4 bg-gray-300 animate-pulse transition duration-50"></div>
          </td>
          <td className="account-executive">
            <div className="w-16 h-4 bg-gray-300 animate-pulse transition duration-50"></div>
          </td>
          <td className="pr-5">
            <div className="w-6 h-4 bg-gray-300 animate-pulse transition duration-50"></div>
          </td>
          <td className="pr-5">
            <div className="w-16 h-4 bg-gray-300 animate-pulse transition duration-50"></div>
          </td>
          <td className="pr-5">
            <div className="w-16 h-4 bg-gray-300 animate-pulse transition duration-50"></div>
          </td>
          <td className="pr-5">
            <div className="w-16 h-4 bg-gray-300 animate-pulse transition duration-50"></div>
          </td>
        </tr>
      ))}
    </>
  );
}

export function FamilyList() {
  const { users, isLoading, refetch } =
    useFamilies();
  const [userView, setUserView] = useState<any>(null);
  const [showBlockList, setShowBlockList] = useState(false);
  const [removeConfirmModal, setRemoveCategoryModal] = useState<any>(null);
  const [blockConfirmModal, setBlockConfirmModal] = useState<any>(null);
  const [showPartialSignups, setShowPartialSignups] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [filters, updateFilters] = useState<any>({});
  const [sortField, setSortField] = useState<String | undefined>()
  const [stateFilter, setStateFilter] = useState('')
  const [statusFilter, setStatusFilter] = useState('')
  const [sortASC, setSortASC] = useState(false)
  const [statesShown, setStatesShown] = useState<{ label: string, value: string }[]>([])

  useEffect(() => {
    const allUserStateData = filteredUsers?.map((user) =>
    ({
      value: zipCodes[user.zipCode]?.state_short,
      label: zipCodes[user.zipCode]?.state
    }));
    setStatesShown(allUserStateData?.filter((state, index) => state.value && allUserStateData.findIndex(s => s.value === state.value) === index));
  }, [users]);

  const filteredUsers = users?.filter((user) => {
    if (filters.keyword) {
      return (
        user.guardianFirstName?.toLowerCase().includes(filters.keyword.toLowerCase()) ||
        user.guardianLastName?.toLowerCase().includes(filters.keyword.toLowerCase()) ||
        user.mobile?.toLowerCase().includes(filters.keyword.toLowerCase()) ||
        user.email?.toLowerCase().includes(filters.keyword.toLowerCase()) ||
        user.firstName?.toLowerCase().includes(filters.keyword.toLowerCase()) ||
        user.lastName?.toLowerCase().includes(filters.keyword.toLowerCase())
      );
    }
    return true;
  });


  filteredUsers.sort((a, b) => {
    if (sortField === 'parentGuardianName') {
      const guardianA = `${a.guardianFirstName} ${a.guardianLastName}`;
      const guardianB = `${b.guardianFirstName} ${b.guardianLastName}`;
      return guardianA.localeCompare(guardianB) * (sortASC ? 1 : -1)
    }
    if (sortField === 'phoneNumber' && a.mobile && b.mobile) {
      return (sortASC ? (a.mobile < b.mobile) : (b.mobile < a.mobile)) ? -1 : 1;
    }
    if (sortField === 'email' && a.email && b.email) {
      return (sortASC ? (a.email < b.email) : (b.email < a.email)) ? -1 : 1;
    }
    if (sortField === 'clientName') {
      const nameA = `${a.firstName} ${a.lastName}`;
      const nameB = `${b.firstName} ${b.lastName}`;
      return nameA.localeCompare(nameB) * (sortASC ? 1 : -1)
    }

    if (sortField === 'provider') {
      const nameA = (a && a.providers && a.providers.length > 0 && a.providers.map(p => `${p.firstName} ${p.lastName}`).join(", ")) || '-'
      const nameB = (b && b.providers && b.providers.length > 0 && b.providers.map(p => `${p.firstName} ${p.lastName}`).join(", ")) || '-'
      if (nameA === '-' && nameB !== '-') return 1;
      if (nameB === '-' && nameA !== '-') return -1;
      return nameA.localeCompare(nameB) * (sortASC ? 1 : -1)
    }

    if (sortField === 'clientCity') {
      const cityA = zipCodes[a.zipCode]?.city;
      const cityB = zipCodes[b.zipCode]?.city;
      if (cityA && !cityB) return -1;
      if (cityB && !cityA) return 1;
      if (!cityA && !cityB) return 0;
      return cityA.localeCompare(cityB) * (sortASC ? 1 : -1)
    }
    if (sortField === 'clientState') {
      const stateA = zipCodes[a.zipCode]?.state;
      const stateB = zipCodes[b.zipCode]?.state;
      if (stateA && !stateB) return -1;
      if (stateB && !stateA) return 1;
      if (!stateA && !stateB) return 0;
      return stateA.localeCompare(stateB) * (sortASC ? 1 : -1)
    }

    if ((sortField === 'joinedOn') && a.joinedOn && b.joinedOn) {
      return (sortASC ? (a.joinedOn < b.joinedOn) : (b.joinedOn < a.joinedOn)) ? -1 : 1;
    }

    if ((sortField === 'lastActive')) {
      const lastActiveA = a.lastActive;
      const lastActiveB = b.lastActive;
      if (lastActiveA && !lastActiveB) return -1;
      if (lastActiveB && !lastActiveA) return 1;
      if (!lastActiveA && !lastActiveB) return 0;

      return (sortASC ? (lastActiveA < lastActiveB) : (lastActiveB < lastActiveA)) ? -1 : 1;
    }

    if ((!sortField || sortField === 'createdAt') && a.createdAt && b.createdAt) {
      return (sortASC ? (a.createdAt < b.createdAt) : (b.createdAt < a.createdAt)) ? -1 : 1;
    }

    return 0;
  })

  const sortToggleClick = (field) => () => {
    if (sortField === field) {
      if (sortASC) {
        setSortASC(false)
      } else {
        setSortASC(true)
        setSortField(undefined)
      }
    } else {
      setSortField(field)
      setSortASC(true)
    }
  }

  const headers = [
    { value: "clientName", label: "Client Name" },
    { value: "parentGuardianName", label: "Parent/Guardian Name" },
    { value: "phoneNumber", label: "Phone Number" },
    { value: "email", label: "Email" },
    { value: "clientCity", label: "City" },
    { value: "clientState", label: "State" },
    { value: "provider", label: "Provider" },
    { value: "joinedOn", label: "Joined On" },
    { value: "lastActive", label: "Last Active" },
  ]

  const stopPropagate = (e: React.SyntheticEvent) => e.stopPropagation();

  const fetchUsers = async () => {
    try {
      setIsDownloading(true);
      const response = await api.getUsers({
        ...filters,
        type: "PF",
        page: 0,
        limit: 10000,
      });
      const data = response?.items?.map((user) => {
        return [
          `${user.guardianFirstName} ${user.guardianLastName}`,
          user.mobile,
          user.email,
          `${user.firstName} ${user.lastName}`,
          zipCodes[user.zipCode]?.city ?? "-",
          zipCodes[user.zipCode]?.state_short ?? "-",
          (user && user.providers && user.providers.length > 0 && user.providers.map(p => `${p.firstName} ${p.lastName}`).join(", ")) || '-',
          moment.utc(user.joinedOn).local().format("MMM DD,YYYY"),
          user.lastActive ? moment.utc(user.lastActive).local().fromNow() : "-",
        ];
      });
      data.splice(0, 0, [
        "Client Name",
        "Parent/Guardian Name",
        "Phone Number",
        "Email",
        "City",
        "State",
        "Provider",
        "Joined On",
        "Last Active",
      ]);
      const build = buildURI(data, true, undefined, undefined, undefined);
      // download csv file
      const a = document.createElement("a");
      a.href = build;
      a.download = `clients-${moment().format()}.csv`;
      a.click();
      // remove the a tag
      a.remove();
      setIsDownloading(false);
    } catch (error: any) {
      setIsDownloading(false);
      ShowToast({
        type: "error",
        message: error?.response?.data?.message || "Something went wrong",
      });
    }
  };

  return (
    <>
      <LoadAnimatedContainer>
        <div className="mb-5 flex gap-5 flex-col md:flex-row justify-between md:items-center">
          <div className="text-black text-3xl font-bold">Manage Clients</div>
          <div className="flex justify-center"></div>
        </div>
        <div className="bg-white rounded-lg shadow-[10px_14px_40px_0px_rgba(0,71,28,0.04)] p-5">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <div className="rounded-lg border border-[#D9D9E7] focus-within:border-lightGray p-3 flex gap-[6px] items-center">
                <SearchIcon className="[&_path]:stroke-[#8D8E92] w-5 h-5" />
                <input
                  type="text"
                  placeholder="Search"
                  className="outline-none"
                  onChange={(e) => updateFilters({ keyword: e.target.value })}
                />
              </div>
              <SelectInput
                placeholder="Filter by state"
                disabledOptionText="Filter by state"
                data={stateFilter === '' ? statesShown : [{ label: "No Filter", value: '' }, ...statesShown]}
                value={stateFilter ?? ''}
                onChange={(e) => setStateFilter(e.target.value)}
                className="mx-3 p-[12px] border-[#D9D9E7] text-[gray]"
              />
              <div className="pl-2" />
              <SelectInput
                data={
                  statusFilter === '' ?
                    Object.entries(ClientStatus).map(([k, v]) => ({
                      value: v,
                      label: v,
                    }))
                    :
                    [{ label: "No Filter", value: '' },
                    ...Object.entries(ClientStatus).map(([k, v]) => ({
                      value: v,
                      label: v,
                    }))]
                }
                name="clientStatus"
                value={statusFilter ?? ''}
                disabledOptionText={"Filter by Status"}
                onChange={(e) => setStatusFilter(e.target.value)}
                className="mx-3 p-[12px] border-[#D9D9E7] text-[gray]"
              />
            </div>

            <div className="flex gap-2 items-center">

              <button
                onClick={() => setShowPartialSignups(true)}
                className="pl-4 pr-3.5 py-3 group rounded-full justify-start items-center flex"
              >
                <div className="text-black text-base group-hover:text-black/80">
                  Partial Client Sign Ups
                </div>
                <ChevronRight className="w-5 h-5 [&_path]:stroke-primary" />
              </button>
              <button
                onClick={() => setShowBlockList(true)}
                className="pl-4 pr-3.5 py-3 group rounded-full justify-start items-center flex"
              >
                <div className="text-black text-base group-hover:text-black/80">
                  Deactivated Clients
                </div>
                <ChevronRight className="w-5 h-5 [&_path]:stroke-primary" />
              </button>
              <Button
                variant="link"
                onClick={() => fetchUsers()}
                disabled={isDownloading}
                className="mr-5 border rounded-md !px-[14px] !py-2 border-primary hover:opacity-80 [&>svg]:!w-6 [&>svg]:!h-6 [&>svg]:!mr-0"
              >
                {isDownloading ? <Loading /> : <DownloadIcon />}
              </Button>
            </div>
          </div>
          <div
            className={`mt-3 text-[14px] h-[calc(100vh-232px)] overflow-auto`}
          >
            <table className="w-full">
              <colgroup>
                <col style={{ width: "17%" }} />
                <col style={{ width: "13%" }} />
                <col style={{ width: "8%" }} />
                <col style={{ width: "19%" }} />
                <col style={{ width: "10%" }} />
                <col style={{ width: "5%" }} />
                <col style={{ width: "11%" }} />
                <col style={{ width: "10%" }} />
                <col style={{ width: "10%" }} />
                <col style={{ width: "6%" }} />
              </colgroup>
              <thead className="[&_td]:bg-[#EEF1EF] [&_td]:py-4">
                <tr className="sticky top-0 z-[1]">
                  {headers.map((header, i) => (<td key={i} className={i === 0 ? "rounded-l pl-5" : ""}>
                    <div className="flex items-center cursor-pointer text-nowrap" onClick={sortToggleClick(header.value)}>
                      {header.label}
                      {sortField === header.value && <div>
                        {sortASC ? <ArrowUp className="w-4 h-4" /> : <ArrowDown className="w-4 h-4" />}
                      </div>}
                    </div>
                  </td>))}
                  <td className="rounded-r"></td>
                </tr>
              </thead>
              <tbody>
                {filteredUsers?.filter(user => statusFilter === '' || (user.clientStatus && user.clientStatus === statusFilter))?.filter(user => stateFilter === '' || (user.zipCode && zipCodes[user.zipCode]?.state_short === stateFilter))?.map((user) => (
                  <tr
                    key={user.id}
                    className="cursor-pointer hover:bg-[#F9F9F9] [&_td]:py-3 border-b border-b-[#EFF1FF]"
                    onClick={() => setUserView(user)}
                  >
                    <td className="name pl-5">
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <div className="flex items-center gap-2.5">
                            <div className="w-10 h-10 rounded-full">
                              {user.profileImg ? (
                                <ImageView
                                  alt="user"
                                  src={user.profileImg}
                                  className="w-full h-full rounded-full object-cover"
                                  loading="lazy"
                                />
                              ) : (
                                <div
                                  className={`uppercase w-10 h-10 rounded-full flex justify-center items-center text-2xl text-primary bg-secondary`}
                                >
                                  {user?.firstName?.[0] || ""}
                                </div>
                              )}
                            </div>
                            <div className="flex gap-1 items-center">
                              <h5 className="flex-1 max-w-[100px] overflow-hidden whitespace-nowrap text-ellipsis" style={{ color: clientStatusColor(user?.clientStatus) }}>
                                {user.firstName} {user.lastName}

                              </h5>
                              {!user?.isInsuranceVerified && (
                                <PendingVerificationTooltip />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      {user?.guardianFirstName}{" "}{user?.guardianLastName}
                    </td>
                    <td className="subscription">
                      <div className="flex items-center gap-2">
                        {user.mobile}
                      </div>
                    </td>
                    <td className="text-ellipsis overflow-hidden whitespace-nowrap max-w-[200px]">
                      {user?.email}
                    </td>
                    <td>
                      {zipCodes[user.zipCode]?.city ?? '-'}
                    </td>
                    <td>
                      {zipCodes[user.zipCode]?.state_short ?? "-"}
                    </td>
                    <td>
                      {(user && user.providers && user.providers.length > 0 && user.providers.map(p => `${p.firstName} ${p.lastName}`).join(", ")) || '-'}
                    </td>
                    <td>
                      {moment.utc(user.joinedOn).local().format("MMM DD,YYYY")}
                    </td>

                    <td>
                      {user.lastActive
                        ? moment.utc(user.lastActive).local().fromNow()
                        : "-"}
                    </td>
                    <td onClick={stopPropagate} className="pr-5">
                      <UIPopover
                        trigger={
                          <span className="block p-2 border border-gray-270 rounded-lg">
                            <More />
                          </span>
                        }
                        positions={["bottom", "top", "left", "right"]}
                      >
                        {(close) => (
                          <FamilyOptionsDropdown
                            onRemove={() => {
                              close();
                              setRemoveCategoryModal(user);
                            }}
                            onBlock={() => {
                              close();
                              setBlockConfirmModal(user);
                            }}
                          />
                        )}
                      </UIPopover>
                    </td>
                  </tr>
                ))}
                {isLoading && <FamilyListLoadingSkeleton />}
                <div className="h-5"></div>
              </tbody>
            </table>
            {isLoading === false && !users?.length && (
              <EmptyStates text="No users found!" />
            )}
          </div>
        </div>
        {userView && (
          <EditFamilyProfileForm
            onClose={() => setUserView(null)}
            user={userView}
            onUpdate={() => refetch(undefined, undefined, true)}
          />
        )}
        {showBlockList && (
          <BlockedFamilyList
            isOpen={showBlockList}
            onClose={() => {
              setShowBlockList(false);
            }}
            onRefresh={() => refetch(undefined, undefined, true)}
          />
        )}
        {showPartialSignups && (
          <PartialSignUpFamilyList isOpen={showPartialSignups} onClose={() => setShowPartialSignups(false)} onRefresh={undefined} />
        )}
        {removeConfirmModal && (
          <DeleteConfirm
            onCancel={() => setRemoveCategoryModal(null)}
            onSuccess={() => {
              refetch(removeConfirmModal.id, true);
              setRemoveCategoryModal(null);
            }}
            user={removeConfirmModal}
          />
        )}
        {blockConfirmModal && (
          <BlockFamilyConfirm
            onCancel={() => setBlockConfirmModal(null)}
            onSuccess={() => {
              refetch(blockConfirmModal.id, true);
              setBlockConfirmModal(null);
            }}
            user={blockConfirmModal}
          />
        )}
      </LoadAnimatedContainer>
    </>
  );
}

export const FamilyOptionsDropdown = ({
  onEdit,
  onBlock,
  onRemove,
}: {
  onEdit?: () => void;
  onBlock: () => void;
  onRemove: (e: React.SyntheticEvent) => void;
}) => {
  return (
    <div className="z-10 w-[150px] flex flex-col justify-center items-center bg-white shadow-[0_6px_24px_0_rgba(28,43,40,0.25)] rounded-xl py-2 mr-8">
      <ul
        className="py-1 text-sm text-gray-700 "
        aria-labelledby="dropdownMenuIconButton"
      >
        <li>
          <button
            className="w-full text-[#212424] px-5 h-10 text-base rounded-[10px] hover:bg-whiteButtonHover"
            onClick={(e) => {
              e.stopPropagation();

              onBlock();
            }}
          >
            Deactivate
          </button>
        </li>
      </ul>
    </div>
  );
};
